<template>
  <div class="orderlist">
    <el-card class="mb-2 tab_mobile">
      <div class="d-flex justify-content-between navbar_style" v-if="model_type == 9">
        <div style="cursor:pointer" v-for="(item, index) of subStatus" :key="index" @click="changeTab(item.value)"
          :class="eventStatus === item.value ? 'checkTab' : ''" class="nav_item">
          {{ item.label }}
        </div>
      </div>
      <div class="d-flex justify-content-between navbar_style" v-else>
        <div style="cursor:pointer" :class="eventStatus === '' ? 'checkTab' : ''" @click="changeTab('')">
          All
        </div>
        <div style="cursor:pointer" v-for="(item, index) of orderStatus" :key="index" @click="changeTab(item.value)"
          :class="eventStatus === item.value ? 'checkTab' : ''" class="nav_item">
          {{ item.label }}
        </div>
      </div>
    </el-card>
    <section v-if="loading">
      <el-skeleton animated>
        <template slot="template">
          <div class="iq-card p-2" v-for="i of 4" :key="i">
            <div class="d-flex justify-content-between pb-2">
              <el-skeleton-item variant="text" style="width:100px;margin-top:15px;" />
              <el-skeleton-item variant="text" style="width:180px;margin-top:15px;" />
              <el-skeleton-item variant="text" style="width:80px;margin-top:15px;" />
            </div>
            <div class="d-flex">
              <el-skeleton-item variant="image" style="width:120px; height: 120px;" />
              <div class="d-flex flex-column ml-2">
                <el-skeleton-item class="mb-2 ml-2" variant="text" style="width:320px;" />
                <el-skeleton-item class="mb-2 ml-2" variant="text" style="width:220px;" />
                <el-skeleton-item class="mb-2 ml-2" variant="text" style="width:200px;" />
                <el-skeleton-item class="ml-2" variant="text" style="width:240px;" />
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <el-skeleton-item variant="text" style="width:100px;margin-top:15px;" />
              <el-skeleton-item variant="text" style="width:80px;height:35px; margin-top:15px;" />
            </div>
          </div>
        </template>
      </el-skeleton>
    </section>
    <section v-else>
      <div v-if="dataList.length > 0">
        <div class="order_pc">
          <b-card v-for="(item, index) in dataList" :key="index" class="mb-3">
            <!-- 商品标题-----订阅 -->
            <div class="row align-items-center" v-if="model_type == 9">
              <div class="col-4 title">
                <h5 class="d-flex align-items-center">
                  <i :class="modelIcon[model_type].icon" class="mb-1" style="color: #50b5ff"></i>
                  <span class="ml-2">{{ modelIcon[model_type].type }}</span>
                </h5>
              </div>
              <div class="col-6 text-center" style="font-size: 13px;">
                Subscription ID: {{ item.subscription_id }}
              </div>
              <div class="col-2 text-right pending">
                <span style="color: #f56c6c" v-if="item.status == -1">All</span>
                <span style="color: #f56c6c" v-if="item.status == 0">Unaction</span>
                <span style="color: #81cc5b" v-if="item.status == 1">Action</span>
                <span style="color: #eab25d" v-if="item.status == 2">Canceled</span>
                <span style="color: #eab25d" v-if="item.status == 3">Expired</span>
              </div>
            </div>

            <!-- 商品标题 -->
            <div class="row align-items-center" v-else>
              <div class="col-3 title">
                <h5 class="d-flex align-items-center">
                  <i :class="modelIcon[model_type].icon" class="mb-1" style="color: #50b5ff"></i>
                  <span class="ml-2">{{ modelIcon[model_type].type }}</span>
                </h5>
              </div>
              <div class="col-6 text-center">
                Order Number :
                {{ item.order_sn || item.order_no | filterSplice25 }}
              </div>
              <div class="col-3 text-right pending">
                <span style="color: #f56c6c" v-if="item.order_status == 0">Not Paid</span>
                <span style="color: #81cc5b" v-if="item.order_status == 1">Paid</span>
                <span style="color: #eab25d" v-if="item.order_status == 2">Free</span>
                <span style="color: #eab25d" v-if="item.order_status == 3">Pending</span>
                <span style="color: #22a1ff" v-if="item.order_status == 4">Refunding</span>
                <span style="color: #eab25d" v-if="item.order_status == 5">Refunded</span>
                <span style="color: #b6b8bc" v-if="item.order_status == 6">Cancel</span>
              </div>
            </div>

            <hr />
            <div>
              <div data-toggle="modal">
                <div class="row pb-2 b-border" v-if="model_type == 5">
                  <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName">
                    <div class="titles d-flex flex-column ml-2">
                      <div class="wodl">
                        <span style="color: #333;">Application Type: </span>
                        <span>{{
                          item.type ? "Probationary Member" : "Member"
                          }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Organization: </span>
                        <span>{{ item.organization }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 b-border" v-if="model_type == 8">
                  <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName">
                    <div class="titles d-flex flex-column ml-2">
                      <div class="wodl">
                        <span style="color: #333;">Donation Type: </span>
                        <span>{{
                          { 1: "JCI", 2: "Foundation", 3: "Other" }[
                          item.donated_type
                          ]
                          }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Donate To: </span>
                        <span>{{ item.donate_to }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Donation Amount: </span>
                        <span>{{ item.currency ? "€" : "$" }}
                          {{ item.order_amount }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Commission: </span>
                        <span>{{ item.currency ? "€" : "$" }} {{ item.fee }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 捐赠订阅 -->
                <div class="row pb-2 b-border" v-else-if="model_type == 9">
                  <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName userName_sub">
                    <div class="titles ml-2">
                      <div class="wodl">
                        <span style="color: #333;">Donation Type: </span>
                        <span>Foundation</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Donate To: </span>
                        <span>JCl Foundation</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Start Date: </span>
                        <span>{{item.start_date}}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Has End Date: </span>
                        <span>{{item.end_date?'Yes':'No'}}</span>
                      </div>
                      <div class="wodl" v-if="item.end_date">
                        <span style="color: #333;">End Date: </span>
                        <span>{{item.end_date?item.end_date:'∞'}}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Donation Amount: </span>
                        <span>$ {{item.order_amount}}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Commission: </span>
                        <span>$ {{( Number(item.pay_amount)-Number(item.order_amount)).toFixed(2) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 b-border cursor-pointer" v-else @click="toDetail(item)">
                  <div class="col-md-3 col-sm-12 pb-2 text-center">
                    <el-image :src="item.cover" cover class="elimageBox">
                      <div slot="error" class="image-slot">
                        <img :src="errorImage" class="errorpng" alt="" />
                      </div>
                    </el-image>
                  </div>

                  <div class="col-md-9 col-sm-12 pl-md-0 pl-sm-5 userName title_style">
                    <div class="titles d-flex flex-column">
                      <div class="wodl">
                        <span style="color: #333;">Title: </span>
                        <span>{{ item.title }}</span>
                      </div>
                      <section v-if="model_type != 6">
                        <div class="wodl">
                          <span style="color: #333;">Package Type: </span>
                          <span v-if="model_type == 2 || model_type == 4">{{
                            item.order_type == 1 ? "Package" : "Single"
                            }}</span>
                          <span v-else>{{
                            item.package_type == 2 ? "Package" : "Single"
                            }}</span>
                        </div>
                        <div class="wodl" v-if="item.order_type == 1">
                          <span style="color: #333;">Package Name: </span>
                          <span>{{ item.setmeal_title }}</span>
                        </div>
                      </section>
                      <section v-if="model_type == 2 || model_type == 4">
                        <div class="wodl">
                          <span style="color: #333;">Quantity: </span>
                          <span>{{ item.number }}</span>
                        </div>
                        <div class="wodl">
                          <span style="color: #333;">Is Early Bird: </span>
                          <span>{{
                            item.is_use_bird_price == 0 ? "No" : "Yes"
                            }}</span>
                        </div>
                      </section>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 总价 -->
              <div class="row d-flex align-items-center total_mobile">
                <div class="col-md-3 col-sm-3 text-left d-flex align-items-center">
                  <section v-if="model_type == 8||model_type == 9">
                    <div class="mt-2">
                      Total:
                      <strong>
                        {{ item.currency ? "€" : "$" }}
                        {{ item.pay_amount }}</strong>
                    </div>
                  </section>
                  <section v-else>
                    <p class="mt-1 mb-0" v-if="item.order_status == 2">
                      {{ $t("Order.7bb@total") }}:
                      <strong> Free</strong>
                    </p>
                    <p class="mt-1 mb-0" v-else>
                      {{ $t("Order.7bb@total") }}:
                      <strong>
                        {{ item.currency ? "€" : "$"
                        }}{{ item.order_amount || item.pay_money }}</strong>
                    </p>
                  </section>
                </div>

                <div class="col-md-9 col-sm-9 text-right orderPriceBt d-flex align-items-center justify-content-end">
                  <!-- <div class="countdownShow col-md-5 col-sm-5" style="display: flex" v-if="[4,6,8].includes(model_type)">
                    <div class="countdown_content" v-if="
                        item.countDownTime != 'Order closed' &&
                        item.order_status == 0&&item.countDownTime
                      ">
                      {{ $t("Order.d8a@countdown") }}:
                      <div style="color: red">
                        &nbsp; {{ item.countDownTime }}
                      </div>
                    </div>
                  </div> -->
                  <div class="countdownShow col-md-5 col-sm-5" style="display: flex"
                    v-if="[2, 4, 6, 8].includes(model_type)">
                    <div class="countdown_content" v-if="item.order_status == 0 && item.remainder != 0">
                      {{ $t("Order.d8a@countdown") }}:
                      <div style="color: red">&nbsp; {{ item.timeDown }}</div>
                    </div>
                  </div>

                  <!-- 订阅---按钮 -->
                  <div class="text-right" style="display: flex; justify-content: flex-end" v-if="model_type == 9">
                    <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px"
                      @click="CancelEvent(item)" v-if="item.status==1||item.status == 0">
                      {{ $t("Order.03e@cancel") }}
                    </button>
                    <button type="button" class="btn btn-primary ml-3" style="width: 100px" v-if="item.status == 0"
                      @click="PayEvent(item)">Subscribe</button>
                    <button type="button" class="btn btn-primary ml-3" style="width: 100px"
                      @click="getSubscriptionList(item)">
                      Check
                    </button>
                  </div>

                  <div class="text-right col-md-7 col-sm-7" style="display: flex; justify-content: flex-end" v-else>
                    <div class="d-flex align-items-center cursor-pointer"
                      v-if="item.order_status == 1 || item.order_status == 2">
                      <img style="width: 25px; height: 22px" @click="jumpInvoice(item.id)"
                        src="@/assets/images/icon/invoice.png" alt="" title="Invoice" />
                    </div>

                    <!-- <section class="d-flex align-items-center" v-if="model_type==5">
                      <button type="button" class="btn btn-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 0" @click="PayEvent(item)">
                        {{ $t("Order.f2d@pay") }}
                      </button>

                      <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 0&&model_type==8" @click="DeleteEvent(item)">
                        {{ $t("Order.edb@delete") }}
                      </button>
                    </section> -->

                    <section class="d-flex align-items-center">
                      <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 0" @click="CancelEvent(item)">
                        {{ $t("Order.03e@cancel") }}
                      </button>

                      <button type="button" class="btn btn-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 0" @click="PayEvent(item)">
                        {{ $t("Order.f2d@pay") }}
                      </button>

                      <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px" v-if="
                          item.order_status == 2 &&
                            item.is_refund &&
                            [3, 4, 5, 7].includes(item.pay_type)
                        " @click="RefundEvent(item)">
                        Refund
                      </button>

                      <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 6" @click="DeleteEvent(item)">
                        {{ $t("Order.edb@delete") }}
                      </button>
                      <button type="button" class="btn btn-primary ml-3" style="width: 100px" @click="checkEvent(item)">
                        Check
                      </button>
                    </section>
                  </div>

                </div>
              </div>
            </div>
          </b-card>
        </div>

        <div class="order_mobile">
          <b-card v-for="(item, index) in dataList" :key="index" class="mb-3">
            <!-- 商品标题-----订阅 -->
            <div class="d-flex flex-column" v-if="model_type == 9">
              <div class="title d-flex justify-content-between">
                <h5 class="d-flex align-items-center">
                  <i :class="modelIcon[model_type].icon" class="mb-1" style="color: #50b5ff"></i>
                  <span class="ml-2 title_size">{{ modelIcon[model_type].type }}</span>
                </h5>
                <div class="text-right pending">
                  <span style="color: #f56c6c" v-if="item.status == -1">All</span>
                  <span style="color: #f56c6c" v-if="item.status == 0">Unaction</span>
                  <span style="color: #81cc5b" v-if="item.status == 1">Action</span>
                  <span style="color: #eab25d" v-if="item.status == 2">Canceled</span>
                  <span style="color: #eab25d" v-if="item.status == 3">Expired</span>
                </div>
              </div>
              <div class="ml-3" style="font-size: 12px;">
                Subscription ID: {{ item.subscription_id }}
              </div>
            </div>
            <!-- 商品标题 -->
            <div class="align-items-center d-flex justify-content-between" v-else>
              <div class="title">
                <h5 class="d-flex align-items-center">
                  <i :class="modelIcon[model_type].icon" style="color: #50b5ff"></i>
                  <span class="ml-2 title_size">{{ modelIcon[model_type].type }}</span>
                </h5>
              </div>
              <div class="number_size">
                Order Number :
                {{ item.order_sn || item.order_no | filterSplice25 }}
              </div>
              <div class="text-right pending">
                <span style="color: #f56c6c" v-if="item.order_status == 0">Not Paid</span>
                <span style="color: #81cc5b" v-if="item.order_status == 1">Paid</span>
                <span style="color: #eab25d" v-if="item.order_status == 2">Free</span>
                <span style="color: #eab25d" v-if="item.order_status == 3">Pending</span>
                <span style="color: #22a1ff" v-if="item.order_status == 4">Refunding</span>
                <span style="color: #eab25d" v-if="item.order_status == 5">Refunded</span>
                <span style="color: #b6b8bc" v-if="item.order_status == 6">Cancel</span>
              </div>
            </div>
            <hr />
            <div>
              <div data-toggle="modal">
                <div class="row pb-2 b-border" v-if="model_type == 5">
                  <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName">
                    <div class="titles d-flex flex-column ml-2">
                      <div class="wodl">
                        <span style="color: #333;">Application Type: </span>
                        <span>{{
                          item.type ? "Probationary Member" : "Member"
                          }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Organization: </span>
                        <span>{{ item.organization }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 b-border" v-else-if="model_type == 8">
                  <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName">
                    <div class="titles d-flex flex-column ml-2">
                      <div class="wodl">
                        <span style="color: #333;">Donation Type: </span>
                        <span>{{
                          { 1: "JCI", 2: "Foundation", 3: "Other" }[
                          item.donated_type
                          ]
                          }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Donate To: </span>
                        <span>{{ item.donate_to }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Donation Amount: </span>
                        <span>{{ item.currency ? "€" : "$" }}
                          {{ item.order_amount }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Commission: </span>
                        <span>{{ item.currency ? "€" : "$" }} {{ item.fee }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 捐赠订阅 -->
                <div class="row pb-2 b-border" v-else-if="model_type == 9">
                  <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName userName_sub">
                    <div class="titles d-flex flex-column">
                      <div class="wodl">
                        <span style="color: #333;">Donation Type: </span>
                        <span>Foundation</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Donate To: </span>
                        <span>JCl Foundation</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Start Date: </span>
                        <span>{{item.start_date}}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Has End Date: </span>
                        <span>{{item.end_date?'Yes':'No'}}</span>
                      </div>
                      <div class="wodl" v-if="item.end_date">
                        <span style="color: #333;">End Date: </span>
                        <span>{{item.end_date?item.end_date:'∞'}}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Donation Amount: </span>
                        <span>$ {{item.order_amount}}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Commission: </span>
                        <span>$ {{( Number(item.pay_amount)-Number(item.order_amount)).toFixed(2) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="b-border cursor-pointer d-flex" v-else @click="toDetail(item)">
                  <div class="pb-2 text-center">
                    <el-image :src="item.cover" cover class="elimageBox">
                      <div slot="error" class="image-slot">
                        <img :src="errorImage" class="errorpng" alt="" />
                      </div>
                    </el-image>
                  </div>

                  <div class="pl-md-0 pl-sm-5 userName title_style">
                    <div class="titles d-flex flex-column">
                      <div class="wodl">
                        <span style="color: #333;">Title: </span>
                        <span>{{ item.title }}</span>
                      </div>
                      <section v-if="model_type != 6">
                        <div class="wodl">
                          <span style="color: #333;">Package Type: </span>
                          <span v-if="model_type == 2 || model_type == 4">{{
                            item.order_type == 1 ? "Package" : "Single"
                            }}</span>
                          <span v-else>{{
                            item.package_type == 2 ? "Package" : "Single"
                            }}</span>
                        </div>
                        <div class="wodl" v-if="item.order_type == 1">
                          <span style="color: #333;">Package Name: </span>
                          <span>{{ item.setmeal_title }}</span>
                        </div>
                      </section>
                      <section v-if="model_type == 2 || model_type == 4">
                        <div class="wodl">
                          <span style="color: #333;">Quantity: </span>
                          <span>{{ item.number }}</span>
                        </div>
                        <div class="wodl">
                          <span style="color: #333;">Is Early Bird: </span>
                          <span>{{
                            item.is_use_bird_price == 0 ? "No" : "Yes"
                            }}</span>
                        </div>
                      </section>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 总价 -->
              <div class="d-flex align-items-center total_mobile justify-content-between">
                <div class="text-left d-flex align-items-center">
                  <section v-if="model_type == 8||model_type == 9">
                    <div class="mt-2">
                      Total:
                      <strong>
                        {{ item.currency ? "€" : "$" }}
                        {{ item.pay_amount }}</strong>
                    </div>
                  </section>
                  <section v-else>
                    <p class="mt-1 mb-0" v-if="item.order_status == 2">
                      {{ $t("Order.7bb@total") }}:
                      <strong> Free</strong>
                    </p>
                    <p class="mt-1 mb-0" v-else>
                      {{ $t("Order.7bb@total") }}:
                      <strong>
                        {{ item.currency ? "€" : "$"
                        }}{{ item.order_amount || item.pay_money }}</strong>
                    </p>
                  </section>
                </div>

                <div class="d-flex align-items-center justify-content-end">
                  <div class="countdownShow" style="display: flex" v-if="[2, 4, 6, 8].includes(model_type)">
                    <div class="countdown_content" v-if="item.order_status == 0 && item.remainder != 0">
                      {{ $t("Order.d8a@countdown") }}:
                      <div style="color: red">&nbsp; {{ item.timeDown }}</div>
                    </div>
                  </div>

                  <div class="text-right" style="display: flex; justify-content: flex-end">
                    <div class="d-flex align-items-center cursor-pointer"
                      v-if="item.order_status == 1 || item.order_status == 2">
                      <img style="width: 25px; height: 22px" @click="jumpInvoice(item.id)"
                        src="@/assets/images/icon/invoice.png" alt="" title="Invoice" />
                    </div>

                    <!-- 订阅---按钮 -->
                    <section class="d-flex align-items-center" v-if="model_type == 9">
                      <button type="button" class="btn btn-primary ml-3 btn_mobile"
                        v-if="item.status==1||item.status == 0" @click="CancelEvent(item)">
                        {{$t("Order.03e@cancel")}}</button>
                      <button type="button" class="btn btn-primary ml-3 btn_mobile" v-if="item.status == 0"
                        @click="PayEvent(item)">Subscribe</button>
                      <button type="button" class="btn btn-primary ml-3 btn_mobile"
                        @click="getSubscriptionList(item)">Check</button>
                    </section>

                    <section class="d-flex align-items-center" v-else>
                      <button type="button" class="btn btn-outline-primary ml-3 btn_mobile"
                        v-if="item.order_status == 0" @click="CancelEvent(item)">
                        {{ $t("Order.03e@cancel") }}
                      </button>

                      <button type="button" class="btn btn-primary ml-3 btn_mobile" v-if="item.order_status == 0"
                        @click="PayEvent(item)">
                        {{ $t("Order.f2d@pay") }}
                      </button>

                      <button type="button" class="btn btn-outline-primary ml-3 btn_mobile" v-if="
                                  item.order_status == 2 &&
                                    item.is_refund &&
                                    [3, 4, 5, 7].includes(item.pay_type)
                                " @click="RefundEvent(item)">
                        Refund
                      </button>

                      <button type="button" class="btn btn-outline-primary ml-3 btn_mobile"
                        v-if="item.order_status == 6" @click="DeleteEvent(item)">
                        {{ $t("Order.edb@delete") }}
                      </button>
                      <button type="button" class="btn btn-primary ml-3 btn_mobile" @click="checkEvent(item)">
                        Check
                      </button>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>

        <el-pagination background layout="prev, pager, next" :page-size="size" :total="totalNum"
          style="text-align: right; margin: 20px 0" @current-change="changePage" :current-page="page"
          hide-on-single-page>
        </el-pagination>
      </div>
    </section>

    <b-card v-if="dataList == ''">
      <div style="background:#fff;height: 500px" class="d-flex justify-content-center align-items-center">
        <img style="width:250px;" :src="emptypath" alt="" />
      </div>
    </b-card>

    <!-- 详情 弹窗 -->
    <b-modal id="Career" centered :title="stateType == 0 ? ' Order Details' : 'Refund rules'" hide-footer>
      <div class="row" v-if="stateType == 0">
        <div class="col-md-3 col-sm-5">
          <span>{{ $t("Order.967@order_time") }}:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left">
          {{ OrderDetailLsit.add_time }}
        </div>
        <div class="col-md-3 col-sm-5">
          <span>{{ $t("Order.14e@order_id") }}:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left">
          <span>{{ OrderDetailLsit.order_sn }}</span>
        </div>
        <!-- <div class="col-md-5 col-sm-5" v-if="
            OrderDetailLsit.pay_type != 0 && OrderDetailLsit.order_status == 2
          ">
          <span>Payment method:</span>
        </div> -->
        <div class="col-md-5 col-sm-5" v-if="
            OrderDetailLsit.pay_type != 0 && OrderDetailLsit.order_status == 2
          ">
          <span>Payment method:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="
            OrderDetailLsit.pay_type != 0 && OrderDetailLsit.order_status == 2
          ">
          <!-- <span v-if="OrderDetailLsit.pay_type==0">undetermined </span> -->
          <span v-if="OrderDetailLsit.pay_type == 1">Free </span>
          <span v-if="OrderDetailLsit.pay_type == 2">Balance </span>
          <span v-if="OrderDetailLsit.pay_type == 3">Credit card </span>
          <span v-if="OrderDetailLsit.pay_type == 4">Offline individuals </span>
          <span v-if="OrderDetailLsit.pay_type == 5">Offline groups </span>
        </div>
        <div class="col-md-5 col-sm-5" v-if="
            OrderDetailLsit.pay_type == 3 && OrderDetailLsit.order_status == 2
          ">
          <span>{{ $t("Order.ddc@thirdparty_id") }}:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="
            OrderDetailLsit.pay_type == 3 && OrderDetailLsit.order_status == 2
          ">
          {{ OrderDetailLsit.transaction_id }}
        </div>
        <div class="col-md-5 col-sm-5" v-if="
            OrderDetailLsit.pay_type != 0 &&
              OrderDetailLsit.pay_time &&
              OrderDetailLsit.order_status == 2
          ">
          <span>{{ $t("Order.234@payment_time") }}:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="
            OrderDetailLsit.pay_type != 0 &&
              OrderDetailLsit.pay_time &&
              OrderDetailLsit.order_status == 2
          ">
          <span>{{ OrderDetailLsit.pay_time }}</span>
        </div>
        <!-- 退款完成展示 退款信息 -->
        <div class="col-5 col-sm-5" v-if="[5, 4, 8].includes(OrderDetailLsit.order_status)">
          <span>Approval time:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="[5, 4, 8].includes(OrderDetailLsit.order_status)">
          {{ OrderDetailLsit.refund_time }}
        </div>
        <div class="col-md-5 col-sm-5" v-if="OrderDetailLsit.order_status == 5">
          <span>Refund Amount:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="OrderDetailLsit.order_status == 5">
          {{ OrderDetailLsit.refund_amount }}
        </div>
        <div class="col-md-7 col-sm-7 text-md-right" v-if="OrderDetailLsit.pay_type == 0">
          <!-- <span v-if="OrderDetailLsit.pay_type==0">undetermined </span> -->
          <span v-if="OrderDetailLsit.pay_type == 1">{{ $t("Order.dbb@free") }}
          </span>
          <span v-if="OrderDetailLsit.pay_type == 2">{{ $t("Order.dde@balance") }}
          </span>
          <span v-if="OrderDetailLsit.pay_type == 3">{{ $t("Order.98c@credit_card") }}
          </span>
          <span v-if="OrderDetailLsit.pay_type == 4">{{ $t("Order.17e@offline_individuals") }}
          </span>
          <span v-if="OrderDetailLsit.pay_type == 5">{{ $t("Order.696@offline_groups") }}
          </span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="
            OrderDetailLsit.order_status == 5 ||
              OrderDetailLsit.order_status == 8
          ">
          {{ OrderDetailLsit.refund_instructions }}
        </div>
      </div>

      <!--退款详情 -->
      <div v-else>
        <!-- <div v-for="(item, index) in RefundSum" :key="index">
          <p>
            {{
              `${index + 1}. Refund period:${
                item.refund_day
              }day. refund amount: $${item.refund_ratio}`
            }}
          </p>
        </div> -->
        <div>
          <span> 1.There is no refund after the event starts</span><br />
          <span>
            2.After payment.the activity can be cancelled in your order</span>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-secondary mr-3" style="width: 100px" @click="Cancelhide">
            {{ $t("Order.82e@cancel") }}
          </button>
          <button type="button" class="btn btn-primary" style="width: 100px" @click="RefundConfirm">
            {{ $t("Order.507@confirm") }}
          </button>
        </div>
      </div>
    </b-modal>

    <!-- 订阅列表弹框 -->
    <el-dialog title="Subscription List" :visible.sync="subscriptionDialog" custom-class="width_40">
      <div class="content_list" v-loading="popupLoading">
        <el-card v-for="(item, index) in subscriptionOrderList" :key="index" class="mb-3">
          <div class="title_popup d-flex justify-content-between align-items-center">
            <span style="color: #777D74;">Order Number:{{item.order_no}}</span>
            <div>
              <span style="color: #f56c6c" v-if="item.order_status == 0">Not Paid</span>
              <span style="color: #81cc5b" v-if="item.order_status == 1">Paid</span>
              <span style="color: #eab25d" v-if="item.order_status == 2">Free</span>
              <span style="color: #eab25d" v-if="item.order_status == 3">Pending</span>
              <span style="color: #22a1ff" v-if="item.order_status == 4">Refunding</span>
              <span style="color: #eab25d" v-if="item.order_status == 5">Refunded</span>
              <span style="color: #b6b8bc" v-if="item.order_status == 6">Cancel</span>
            </div>
          </div>
          <hr />
          <!-- 捐赠订阅 -->
          <div class="row pb-2 b-border">
            <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName" style="height: 80px;">
              <div class="titles ml-2">
                <div class="wodl">
                  <span style="color: #333;">Donation Amount: </span>
                  <span>$ {{item.order_amount}}</span>
                </div>
                <div class="wodl">
                  <span style="color: #333;">Commission: </span>
                  <span>$ {{item.fee}}</span>
                </div>
                <div class="wodl">
                  <span style="color: #333;">Created At: </span>
                  <span>{{item.created_at}}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 总价 -->
          <div class="d-flex align-items-center total_mobile justify-content-between">
            <div class="text-left d-flex align-items-center">
              <section>
                <div class="mt-2">
                  Total:
                  <strong>
                    {{ item.currency ? "€" : "$" }}
                    {{ item.pay_amount }}</strong>
                </div>
              </section>
            </div>

            <div class="text-right" style="display: flex; justify-content: flex-end">
              <section class="d-flex align-items-center">
                <button type="button" class="btn btn-primary ml-3 btn_mobile" style="width: 100px;"
                  v-if="item.order_status == 0" @click="paySubscription(item)">Pay</button>
                <button type="button" class="btn btn-primary ml-3 btn_mobile" style="width: 100px;"
                  @click="checkEvent(item)">Check</button>
              </section>
            </div>

          </div>

        </el-card>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        emptypath: require("@/assets/images/group/empty1.png"), //     <!-- fail -->
        errorImage: require("@/assets/images/public/fail.png"),
        dataList: "",

        orderLists: "",
        OrderDetailLsit: {},
        stateType: 0,
        RefundSum: "",
        itemID: "",
        time: 400,
        record: 0,
        modelIcon: {
          2: { icon: "ri-calendar-event-line", type: "Event" },
          4: { icon: "ri-slideshow-3-line", type: "Meeting" },
          6: { icon: "ri-award-line", type: "Program" },
          5: { icon: "ri-vip-crown-line", type: "Membership" },
          8: { icon: "ri-money-dollar-circle-line", type: "Donation" },
          9: { icon: "ri-money-dollar-circle-line", type: "Donation Subscription" },
        },
        page: 1,
        size: 10,
        totalNum: 0,
        type: 0,
        payType: 0,
        cType: "",
        model_type: 2,
        loading: true,
        orderStatus: [
          { label: "Not Paid", value: 0 },
          { label: "Paid", value: 1 },
          { label: "Free", value: 2 },
          { label: "Pending", value: 3 },
          { label: "Cancel", value: 6 },
          { label: "Refunding", value: 4 },
          { label: "Refunded", value: 5 }
        ],
        subStatus: [
          { label: "All", value: -1 },
          { label: "Unaction", value: 0 },
          { label: "Action", value: 1 },
          { label: "Canceled", value: 2 },
          { label: "Expired", value: 3 },
        ],
        eventStatus: "",
        subscriptionDialog: false,
        subscriptionOrderList: [],
        popupLoading: false
      };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
      "$route.query.model_type"(newVal, oldVal) {
        this.model_type = Number(newVal);
        this.page = 1;
        this.dataList = [];
        if (this.model_type == 9) {
          this.eventStatus = -1
        } else {
          this.eventStatus = ''
        }
        this.getOrderList();
      }
    },

    // 组件实例创建完成，DOM未生成，
    created() {
      this.model_type = this.$route.query.model_type;
      if (this.model_type) {
        this.model_type = Number(this.model_type);
      }
      if (this.model_type == 9) {
        this.eventStatus = -1
      } else {
        this.eventStatus = ''
      }
      this.getOrderList();
    },

    filters: {
      filterSplice25(val) {
        if (val) return val.slice(0, 25);
      },
      filterAwardType(val) {
        return val == 1 ? "Program" : "Program Certificate";
      }
    },
    mounted() { },

    methods: {
      // event order Tab
      changeTab(i) {
        this.eventStatus = i;
        this.getOrderList();
      },
      checkEvent(item) {
        // console.log(item);
        this.$router.push({
          path: "/orderDet",
          query: {
            id: item.id,
            type: this.model_type == 9 ? '8' : this.model_type
          }
        });
      },
      // 发票
      jumpInvoice(id) {
        this.$router.push({
          path: "/invoice",
          query: {
            type: this.model_type,
            id: id,
            sonType: 0
          }
        });
      },

      changePage(item) {
        this.page = item;
        this.getOrderList();
      },

      getOrderList() {
        this.loading = true;
        let ajaxType = {
          2: "eventOrderList",
          4: "meetingOrderList",
          5: "vipOrderList",
          6: "programOrderList",
          8: "donationOrderList",
          9: "donationSubscriptionList",
        };
        this.$http[ajaxType[this.model_type]]({
          [["2", "4", "5", "6", "8"].includes(this.$route.query.model_type) ? "order_status" : "status"]: this.eventStatus,
          page: this.page,
          per_page: this.size
        }).then(res => {
          if (res.status == 200) {
            this.dataList = res.data.data || [];
            this.totalNum = res.data.total;
            this.dataList.forEach(item => {
              if (item.remainder > 0) {
                this.eventDown(item);
              }
            });
            // 开始倒计时
            for (let i in this.dataList) {
              this.dataList[i].countDownTime = "";
              this.countDown(i);
            }
          }
          setTimeout(() => {
            this.loading = false;
          }, 0);
        });
      },
      eventDown(item) {
        let num = item.remainder;
        setInterval(() => {
          if (num > 0) {
            num--;
            let m =
              Math.floor(num / 60) < 10
                ? "0" + Math.floor(num / 60)
                : Math.floor(num / 60);
            let s = num % 60 < 10 ? "0" + (num % 60) : num % 60;
            item.timeDown = m + ":" + s;
          } else {
            item.timeDown = 0;
          }
        }, 1000);
        if (num <= 0) {
          clearInterval();
        }
      },
      getOrderDetail(id) {
        let ajaxType = {
          1: "getOrderDetail",
          2: "eventOrderDetail",
          3: "courseOrderDetail",
          4: "meetingOrderDetail",
          5: "courseCertificateOrderDetail",
          6: "courseCertificateOrderDetail",
          7: "getDonateOrderDetail",
          // 9: "programOrderDetail"
        };
        this.$http[ajaxType[this.model_type]]({ id: id }).then(res => {
          if (res.status == 200) {
            this.OrderDetailLsit = res.data[0];
          }
        });
      },

      orderDetails(item) {
        this.stateType = 0;
        this.$bvModal.show("Career");
        this.getOrderDetail(item.id);
      },

      // 跳支付
      PayEvent(item) {
        this.$router.push({
          path: "/PayMoney",
          query: {
            model_type: this.model_type,
            id: item.pay_id || item.id,
            [[2, 4].includes(this.model_type) ? "model_id" : ""]:
              this.model_type == 2 ? item.event_id : item.meeting_id,
            [[2, 4].includes(this.model_type)
              ? "package_type"
              : ""]: item.package_type,
            [this.model_type == 9 ? 'is_subscribe' : '']: 2
          }
        });
      },

      // 取消
      CancelEvent(item) {
        let ajaxType = {
          2: "eventOrderCancel",
          4: "meetingOrderCancel",
          5: "vipOrderCancel",
          6: "cancelProgramOrder",
          8: "donationOrderCancel",
          9: "donationSubscriptionCancel"
        };
        this.$confirm("Are you sure you want to cancel?", "Tips", {
          distinguishCancelAndClose: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }).then(() => {
          this.$http[ajaxType[this.model_type]]({ id: item.id }).then(res => {
            if (res.status == 200) {
              this.getOrderList();
            }
          });
        });
      },

      // 删除
      DeleteEvent(item) {
        let ajaxType = {
          2: "eventOrderDelete",
          4: "meetingOrderDelete",
          5: "vipOrderDelete",
          6: "delProgramOrder",
          8: "donationOrderDelete"
        };
        let data = { id: item.id };
        this.$confirm("Are you sure you want to delete the order", {
          cancelButtonText: "Cancel",
          confirmButtonText: "Confirm",
          type: "warning"
        }).then(() => {
          this.$http[ajaxType[this.model_type]](data).then(res => {
            if (res.status == 200) {
              this.getOrderList();
            }
          });
        });
      },

      // 退款信息
      RefundEvent(item) {
        let newTime = new Date();
        if (newTime.getTime() - this.record > this.time) {
          if (item.is_refund == 0) {
            this.$message.warning("No refund is allowed for this order");
          } else {
            this.itemID = item.id;
            this.stateType = 1;
            this.$bvModal.show("Career");
          }
        }
        this.record = new Date().getTime();
      },

      // 确认申请退款
      RefundConfirm() {
        let ajaxType = {
          2: "eventRefundApply",
          4: "meetingRefundApply",
          6: "applyProgramRefund"
        };

        let newTime = new Date();
        if (newTime.getTime() - this.record > this.time) {
          this.$http[ajaxType[this.model_type]]({
            id: this.itemID
          }).then(res => {
            if (res.status == 200) {
              this.$bvModal.hide("Career");
              this.getOrderList();
            }
          });
        }
        this.record = new Date().getTime();
      },

      // 关闭退款
      Cancelhide() {
        this.$bvModal.hide("Career");
        // this.getOrderList(this.model_type)
      },

      // 倒计时
      TimeDifference(Stime, Ttime) {
        // 判断开始时间是否大于结束日期
        if (Stime > Ttime) {
          return false;
        }
        if (Ttime && Stime) {
          var startDate = new Date(Stime);
          var stopDate = new Date(Ttime);
          var startTime = startDate.getTime();
          var stopTime = stopDate.getTime();
          var cTime = Number(stopTime) - Number(startTime);
          var secondTime = cTime / 1000 / 60;
          return parseInt(secondTime);
        } else {
          return 0;
        }
      },

      countDownFun(time) {
        let tiemsd = time;
        let update_time = Math.round(new Date(tiemsd) / 1000);

        const end = update_time * 1000; // 后端返回时毫秒级
        const now = new Date(this.getCurrentTime()).getTime(); // 获取当前时间的毫秒数
        // console.log(end, '倒计时间');
        // console.log(now, '当前时间');
        const minus = now - end; // 获取  后端返回来 和当前时间差
        const m_30 = 10 * 60 * 1000; // 十分钟 毫秒级
        // console.log(m_30, '十分钟时间');
        const differ = m_30 - minus; //
        // console.log(differ, '倒计时开始时间');
        let m = parseInt((differ / 1000 / 60) % 60);
        let s = parseInt((differ / 1000) % 60);
        if (m >= 0 && s >= 0) {
          if (m == 0 && s == 0) {
            return "Order closed";
          } else {
            m = (m < 10 ? "0" : "") + m;
            s = (s < 10 ? "0" : "") + s;
            return m + ":" + s;
          }
        }
      },

      countDown(i) {
        let that = this;
        let item = that.dataList[i];

        // console.log(this.getCurrentTime())
        if (that.TimeDifference(item.created_at, this.getCurrentTime()) < 10) {
          that.dataList[i].countDownFn = setInterval(() => {
            if (
              that.TimeDifference(item.created_at, this.getCurrentTime()) < 10
            ) {
              item.countDownTime = that.countDownFun(item.created_at);

              that.$set(
                that.dataList,
                item.countDownTime,
                that.countDownFun(item.created_at)
              );
              if (item.countDownTime == "00:01") {
                location.reload();
              }
            } else {
              item.countDownTime = "Order closed";
              that.$set(that.dataList, item.countDownTime, "Order closed");
              // clearInterval(that.dataList[i].countDownFn); //清除定时器
            }
            if (item.countDownTime == "Order closed") {
              clearInterval(that.dataList[i].countDownFn); // 清除定时器
            }
          }, 1000);
        } else {
          item.countDownTime = "Order closed";
          that.$set(that.dataList, item.countDownTime, "Order closed");
        }
      },

      getCurrentTime() {
        // t为时区参数  默认东八区北京时间正  西为负

        let t;
        if (this.$getlocalStorage("time_zones")) {
          t = -6;
        } else {
          t = 8;
        }

        const time = new Date();
        const len = time.getTime();
        const offset = time.getTimezoneOffset() * 60000; // 本地时间与GMT时间差值
        const utcTime = len + offset; // 格林尼治时间

        const date = new Date(utcTime + 3600000 * t); // 格林尼治时间和当前时区差值
        const y = date.getFullYear();
        const mon = date.getMonth() + 1;
        const d = date.getDate();
        const h = t == -6 ? date.getHours() + 1 : date.getHours();
        const m = date.getMinutes();
        const s = date.getSeconds();
        //
        // 不足两位补0
        function addZero(value) {
          if (value < 10) return "0" + value;
          else return value;
        }
        const result =
          y +
          "-" +
          addZero(mon) +
          "-" +
          addZero(d) +
          " " +
          addZero(h) +
          ":" +
          addZero(m) +
          ":" +
          addZero(s);
        return result;
      },

      // -------------to项目/课程/活动/会议detail------------
      toDetail(item) {
        if (item.operation_type == 1 || item.is_close == 1) {
          let typeId = {
            2: "event_id",
            4: "meeting_id",
            6: "program_id"
          };
          let path = {
            2: "/event-detail",
            4: "/meeting-details",
            6: "/award-detail"
          };
          this.$router.push({
            path: path[this.model_type],
            query: {
              id: item[typeId[this.model_type]],
              type: this.model_type
            }
          });
        } else {
          this.$message.error("The product has been taken down!");
        }
      },

      // 订阅列表弹框
      getSubscriptionList(item) {
        this.subscriptionDialog = true
        this.sub_id = item.id
        this.donationSubscriptionOrderList(item.id)
      },

      // 获取订阅订单列表【Donation】
      async donationSubscriptionOrderList(id) {
        this.subscriptionOrderList = []
        this.popupLoading = true
        let res = await this.$http.donationSubscriptionOrderList({ id })
        if (res.status == 200) {
          this.subscriptionOrderList = res.data.data
        }
        this.popupLoading = false
      },

      // 订阅订单去支付
      paySubscription(item) {
        window.open(item.payment_link, '_blank')
        this.popupCheckPay()
      },

      // 弹框查询支付状态
      popupCheckPay() {
        this.$confirm('Has the payment been confirmed?', 'Tips',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
        ).then(() => {
          this.donationSubscriptionOrderList(this.sub_id)
          this.getOrderList()
        })
      }
    }
  };
</script>

<style scoped lang="less">
  @media (max-width: 768px) {
    .order_pc {
      display: none;
    }

    ::v-deep .width_40 {
      width: 100%;

      /* margin-top: 0 !important; */
      .el-dialog__body {
        padding: 10px 5px !important;
      }

      .btn_mobile {
        width: 4.8rem !important;
      }
    }

    .content_list {
      /* max-height: 100vh; */
      overflow-y: auto;
    }

    .tab_mobile {
      ::v-deep .el-card__body {
        padding: 15px 10px;
      }
    }

    .order_mobile {
      .card-body {
        padding: .9375rem;
      }

      .title_size {
        font-size: 14px !important;
      }

      .number_size {
        font-size: 11px;
        text-align: center;
        max-width: 10.5rem;
      }
    }

    .elimageBox {
      width: 3.75rem !important;
      height: 3.75rem !important;
    }

    .userName {
      font-size: 14px;

      .wodl {
        height: 22px !important;
      }
    }

    .navbar_style {
      height: 1.3rem;
      overflow-y: auto;
      scrollbar-width: none;
      white-space: nowrap;
    }

    .nav_item {
      margin-left: 1rem;
    }

    .title_style {
      padding-left: 0.6rem !important;
    }

    .btn_mobile {
      /* width: 4.8rem !important; */
      font-size: 12px !important;
      margin-left: .5rem !important;
      padding: 5px 8px !important;
    }

    .title_popup {
      font-size: 12px;
    }
  }

  @media not screen and (max-width: 768px) {
    .order_mobile {
      display: none;
    }

    ::v-deep .width_40 {
      width: 780px;
      margin-top: 2vh !important;
    }
  }

  .content_list {
    min-height: 300px;
    padding-right: 5px;
    max-height: 82vh;
    overflow-y: auto;
  }

  ::v-deep #Career .modal-dialog {
    max-width: 768px !important;
  }

  .orderlist {
    ::v-deep .el-image__inner {
      vertical-align: top;
      object-fit: cover;
    }

    .checkTab {
      color: #50b5ff;
    }

    .elimageBox {
      width: 120px;
      height: 120px;
      background-color: #eff0f2;
      display: flex;
      align-items: center;
      justify-content: center;

      .errorpng {
        width: 38px;
        height: 31px;
      }
    }

    ::v-deep .ri-more-line:before {
      content: "\ee1d";
      color: #50b5ff;
      font-size: 27px;
      cursor: pointer;
    }

    .btn-outline-primary:hover {
      color: #50b5ff;
    }

    .userName {
      display: flex;
      align-content: space-between;
      flex-direction: column;
      height: 145px;

      .titles {
        /* height: 64px; */

        .wodl {
          height: 25px;
          display: -webkit-box;
          overflow: hidden;
          /* word-break: break-all; */
          text-overflow: ellipsis;

          white-space: pre-line;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }
    }

    .userName_sub {
      height: 175px;
    }

    .trip {
      margin-bottom: 10px;
      max-height: 250px;
      overflow-x: hidden;
      overflow: hidden;
      overflow-y: scroll;
    }

    .trip::-webkit-scrollbar {
      width: 4px !important;
    }

    .trip::-webkit-scrollbar-thumb {
      background-color: #d9d9d9 !important;
    }

    .trip::-webkit-scrollbar-thumb:hover {
      background: #747474 !important;
    }

    .writing {
      height: 100%;

      .title {
        display: -webkit-box;
        /* word-break: break-all; */
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

    .orderPriceBt {
      display: flex;
      align-items: center;

      // justify-content: space-between;

      .countdownShow {
        display: inline-block;
        width: 160px;

        .countdown_content {
          display: flex;
          align-items: center;

          span {
            color: red;
            // max-width: 70px;
            text-align: left;
          }
        }
      }
    }
  }
</style>